<template>
  <p :class="$style.hint">
    <i18n-t :keypath="keypath">
      <template #attention>
        <span :class="$style.marked">
          {{ $t("forms.order_user_data.pubg_hint.attention") }}
        </span>
      </template>
      <template #marked>
        <span :class="$style.marked">
          {{ $t("forms.order_user_data.pubg_hint.marked") }}
        </span>
      </template>
      <template #midasbuy_link>
        <NuxtLink
          :class="$style.link"
          to="https://www.midasbuy.com/midasbuy/ru/redeem/pubgm"
          external
          target="_blank"
        >
          {{ $t("forms.order_user_data.pubg_hint.midasbuy_link") }}
        </NuxtLink>
      </template>
      <template #history_link>
        <SharedNavLink
          :class="$style.link"
          to="profile"
          target="_blank"
        >
          {{ $t("forms.order_user_data.pubg_hint.history_link") }}
        </SharedNavLink>
      </template>
    </i18n-t>
  </p>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    showHistoryLinkSentence?: boolean;
  }>(),
  {
    showHistoryLinkSentence: true,
  },
);
const keypath = computed(() => {
  if (props.showHistoryLinkSentence) {
    return "forms.order_user_data.pubg_hint.main";
  } else {
    return "forms.order_user_data.pubg_hint.main_without_history_link_sentence";
  }
});
</script>

<style lang="scss" module>
.hint {
  padding: var(--spacing-2xs);
}
.marked {
  color: var(--optional-red);
}
.link {
  color: var(--button-primary-default);
}
</style>
