<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.42236 1.66797C2.45344 1.66797 1.66797 2.45344 1.66797 3.42236V13.4157C1.66797 14.3846 2.45344 15.1701 3.42236 15.1701H13.4157C14.3846 15.1701 15.1701 14.3846 15.1701 13.4157V3.42236C15.1701 2.45344 14.3846 1.66797 13.4157 1.66797H3.42236ZM7.57513 18.3347H14.3262C16.5414 18.33 18.3346 16.533 18.3346 14.3178V7.56672C18.3346 7.21718 18.0513 6.93381 17.7017 6.93381C17.3522 6.93381 17.0688 7.21718 17.0688 7.56672V14.3178C17.0711 15.0466 16.7831 15.7464 16.2685 16.2626C15.7539 16.7787 15.055 17.0688 14.3262 17.0688H7.57513C7.22885 17.0688 6.94683 17.3471 6.94222 17.6933C6.94219 18.0443 7.22422 18.3301 7.57513 18.3347Z"
    />
  </svg>
</template>
