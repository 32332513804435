<template>
  <BaseDropdown
    :show-values="showValues"
    :dropdown-classes="dropdownClasses"
    :caller-classes="[
      useDefaultTheme ? $style.caller : '',
      $style[`caller-${align}`],
      ...callerClasses,
    ]"
    :values-classes="[
      useDefaultTheme ? $style.values : '',
      $style[`values-${align}`],
      ...valuesClasses,
    ]"
    tabindex="0"
    @outside-click="$emit('outsideClick')"
  >
    <template #base-caller>
      <slot name="caller" />
    </template>
    <template #base-values>
      <slot name="values" />
    </template>
  </BaseDropdown>
</template>

<script setup lang="ts">
import BaseDropdown from "./BaseDropdown.vue";

type Props = {
  showValues: boolean;
  align?: "left" | "right";
  useDefaultTheme?: boolean;
  dropdownClasses?: string[];
  callerClasses?: string[];
  valuesClasses?: string[];
};
const props = withDefaults(defineProps<Props>(), {
  align: "right",
  useDefaultTheme: true,
  dropdownClasses: () => [],
  callerClasses: () => [],
  valuesClasses: () => [],
});
const {
  showValues,
  align,
  useDefaultTheme,
  dropdownClasses,
  callerClasses,
  valuesClasses,
} = toRefs(props);
</script>

<style lang="scss" module>
.caller {
  display: flex;
  align-items: center;
}
.caller-left {
  justify-content: flex-start;
}
.caller-right {
  justify-content: flex-end;
}
.values {
  top: calc(100% + 15px);
  width: 280px;
  border-radius: var(--radius-5xl);
  background: var(--general-underlay-2-lvl);
  overflow: hidden;

  & > div {
    max-height: 30dvh;
    padding: var(--spacing-2lg);
    overflow: auto;
  }

  @media screen and (min-width: calc($tablet + 1px)) {
    & > div {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: none;
      }
    }

    &:hover > div::-webkit-scrollbar-thumb {
      background: var(--general-transparent-light-10) !important;
    }
  }
}
.values-left {
  left: 0;
}
.values-right {
  right: 0;
}
</style>
