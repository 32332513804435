<template>
  <div :class="$style.counter">
    <button
      :class="[
        $style.btn,
        !model || model < 0 ? $style.disabled : '',
        $style[size],
      ]"
      @click="decrease"
      @dblclick.prevent
    >
      -
    </button>
    <input
      v-model="model"
      type="number"
      :class="$style['input']"
      :min="min || 0"
    />
    <button
      :class="[
        $style.btn,
        props.max === model ? $style.disabled : '',
        $style[size],
      ]"
      @click="increase"
      @dblclick.prevent
    >
      +
    </button>
  </div>
</template>

<script lang="ts" setup>
const model = defineModel<number>();
const props = withDefaults(
  defineProps<{
    min?: number | null;
    max?: number | null;
    step?: number;
    size?: "l" | "m" | "s" | "xs";
    isOrdering?: boolean;
  }>(),
  { step: 1, size: "m", min: 0 },
);
const { step } = toRefs(props);

function increase() {
  if (model.value !== undefined) model.value += step.value;
}

function decrease() {
  if (model.value === props.min) {
    model.value = 0;
    return;
  }
  if (model.value !== undefined && model.value > 0) {
    const decreaseValue = model.value - step.value;
    model.value = decreaseValue > 0 ? decreaseValue : 0;
  }
}
</script>

<style lang="scss" module>
@use "~/styles/_mixins.scss" as *;
.counter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-3xs);
  font-size: 0.875rem;
  color: var(--general-transparent-light-100);
  line-height: 100%;
  letter-spacing: -0.1px;
  background: var(--input-default);
  border-radius: var(--radius-max);
  transition: 0.25s;
}
.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--radius-max);
  background: var(--general-transparent-mid-10);
  border: none;
  outline: none;
  color: var(--general-white);
  font-size: 1.5rem;
  font-weight: 00;
  transition: 0.25s;
  @include user-selection-none;
  &:hover {
    background: var(--general-transparent-light-35);
  }
  &:active {
    background: var(--general-transparent-light-50);
  }
}
.input {
  text-align: center;
  width: 100%;
  background: var(--input-default);
  color: var(--general-transparent-light-100);
  font-size: 1.25rem;
  border: none;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.l {
  width: 60px;
  height: 60px;
  font-size: 1rem;
}
.m {
  width: 46px;
  height: 46px;
  font-size: 1rem;
}
.s {
  width: 40px;
  height: 40px;
  font-size: 0.875rem;
}
.xs {
  width: 32px;
  height: 32px;
  font-size: 0.875rem;
}
</style>
