export default function toLocalNumber(
  value: number,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 2,
): string {
  return new Intl.NumberFormat("ru", {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}
