<template>
  <div :class="$style.error">
    <slot />
  </div>
</template>
<style lang="scss" module>
.error {
  text-align: center;
  font-family: $regular;
  color: var(--optional-red);
}
</style>
