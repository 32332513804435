<template>
  <div :class="$style.input_error">
    <slot />
  </div>
</template>

<style lang="scss" module>
.input_error {
  text-align: center;
  color: #ff3333;
  font-size: 12px;
  margin-bottom: 8px;
  font-family: $regular;
}
</style>
