<template>
  <Popup :class="$style['form-order-confirm']">
    <PopupHeader
      :show-back-btn="false"
      @hide="emit('hide')"
    >
      <slot name="title">{{ t("forms.order_user_confirm.title") }}</slot>
    </PopupHeader>
    <template
      v-for="item in gameProducts"
      :key="item.data.id"
    >
      <OrderCard
        v-if="item.amount"
        :product="item.data"
        :initial-amount="item.amount"
        :class="$style['form-order-card']"
        :compact="true"
        @change="productsStore.updateAmountUsingId($event)"
      />
    </template>
    <OrderBtnWrapper>
      <OrderBtn
        :content="t('products.products_order.confirm_order')"
        :total-amount="productsStore.totalAmount"
        :total-cost="productsStore.totalCost"
        @click="proceed"
      />
    </OrderBtnWrapper>
  </Popup>
</template>

<script lang="ts" setup>
import Popup from "~/components/Shared/Popup/Popup.vue";
import PopupHeader from "~/components/Shared/Popup/PopupHeader.vue";
import OrderCard from "~/components/Shared/Product/OrderCard.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const gameProducts = computed(() => productsStore.gameData?.products || []);
const emit = defineEmits<{
  (e: "hide"): void;
}>();

const proceed = () => {
  productsStore.showUserDataForm = true;
  productsStore.showOrderConfirmForm = false;
};

watch(
  () => productsStore.totalAmount,
  (v) => {
    if (v <= 0) emit("hide");
  },
);
</script>

<style lang="scss" module>
.form-order-confirm {
  @media screen and (max-width: $mobile) {
    padding-bottom: 120px;
  }
}
.form-order-card {
  margin-bottom: var(--spacing-xs);
}
</style>
