<template>
  <section
    :class="[$style['order-card'], compact && $style.compact]"
    data-test="order-card"
  >
    <div :class="$style['product-data']">
      <div :class="$style['product-picture-wrapper']">
        <img
          v-if="product.picture"
          :src="product.picture"
          :class="$style['product-picture']"
        />
        <img
          v-else
          src="@/assets/product-img.webp"
          :class="$style['product-picture']"
        />
        <SharedDiscount
          v-if="compact && product.discount_percent"
          :class="$style['product-discount']"
          size="s"
        >
          {{ product.discount_percent }}%
        </SharedDiscount>
      </div>
      <div>
        <h5 :class="$style['product-name']">{{ product.title }}</h5>
        <p
          v-if="amount"
          :class="$style.subtitle"
        >
          {{ toLocalNumber(parseFloat(useProductPrice(product)) * amount) }}
          ₽
          <span
            v-if="useProductOldPrice(product)"
            :class="$style['price-discount']"
          >
            {{
              toLocalNumber(
                parseFloat(useProductOldPrice(product) || "") * amount,
              )
            }}
            ₽
          </span>
        </p>
      </div>
    </div>
    <SecondaryButton
      v-if="!amount"
      size="xl"
      :class="$style['price-btn']"
      @click="checkMinValue"
    >
      {{ toLocalNumber(parseFloat(useProductPrice(product))) }} ₽
      <span
        v-if="useProductOldPrice(product)"
        :class="$style['price-discount']"
      >
        {{ toLocalNumber(parseFloat(useProductOldPrice(product) || "0")) }}
      </span>
    </SecondaryButton>
    <Counter
      v-else
      v-model="amount"
      :min="product.min_quantity"
      :max="product.max_quantity"
      :size="compact ? 's' : 'l'"
      :class="[
        $style['order-card-counter'],
        compact && $style['compact-counter'],
      ]"
      @keydown="preventDecimalInput"
    />
  </section>
</template>

<script lang="ts" setup>
import type { Product } from "~/types/products";
import Counter from "~/components/Shared/Product/Counter.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";

const emit = defineEmits<{
  change: [{ productId: number; newAmount: number }];
}>();
const props = withDefaults(
  defineProps<{
    product: Product;
    initialAmount?: number;
    compact?: boolean;
  }>(),
  {
    initialAmount: 0,
    compact: false,
  },
);
function preventDecimalInput(event: KeyboardEvent) {
  // Запрещаем ввод точки (46) и запятой (44)
  if (event.key === "." || event.key === ",") {
    event.preventDefault();
  }
}
const { product, initialAmount, compact } = toRefs(props);
const amount = ref(props.initialAmount || 0);

function checkMinValue() {
  amount.value = props.product.min_quantity ? props.product.min_quantity : 1;
}

watch(initialAmount, (newInitialAmount) => (amount.value = newInitialAmount));
watch(amount, (newAmount) => {
  // сбрасываем значение если значение 0 и ниже
  if (newAmount <= 0) {
    newAmount = 0;
  }
  // Проверяем минимальное к-во
  else if (
    props.product.min_quantity &&
    newAmount < props.product.min_quantity
  ) {
    amount.value = props.product.min_quantity ? props.product.min_quantity : 1;
  }
  // Проверяем максимальное к-во
  else if (
    props.product.max_quantity &&
    newAmount > props.product.max_quantity
  ) {
    newAmount = props.product.min_quantity ? props.product.min_quantity : 1;
  }

  emit("change", {
    productId: product.value.id,
    newAmount,
  });
});
</script>

<style lang="scss" module>
.order-card {
  background-color: var(--app-action-cards-default);
  border-radius: var(--radius-6xl);
  padding: var(--spacing-md);
  @media screen and (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.compact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .product-data {
    display: flex;
    max-width: 55%;
    align-items: center;
    margin-bottom: 0;
  }
  .product-picture-wrapper {
    position: relative;
  }
  .product-picture {
    margin: 0;
    margin-right: var(--spacing-md);
  }
  .product-discount {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .product-name,
  .subtitle {
    text-align: left;
  }
}
.product-data {
  display: flex;
  margin-bottom: var(--spacing-md);
  @media screen and (max-width: $mobile) {
    display: block;
  }
}
.product-picture {
  display: block;
  width: 64px;
  height: 64px;
  margin-right: var(--spacing-md);
  flex-shrink: 0;
  border-radius: 20px;
  @media screen and (max-width: $mobile) {
    margin: auto;
    margin-bottom: var(--spacing-md);
  }
}
.product-name,
.subtitle {
  @media screen and (max-width: $mobile) {
    text-align: center;
  }
}
.product-name {
  margin: var(--spacing-2xs) 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.09px;
  @media screen and (max-width: $tablet) {
    font-size: 1rem;
  }
  @media screen and (max-width: $mobile) {
    font-size: 0.875rem;
  }
}
.subtitle {
  color: var(--general-transparent-light-50);
  font-size: 0.75rem;
  line-height: 110%;
  letter-spacing: -0.06px;
  @media screen and (max-width: $tablet) {
    font-size: 0.625rem;
  }
  @media screen and (max-width: $mobile) {
    font-size: 0.5rem;
  }
}
.price-btn {
  width: 100%;
  @media screen and (max-width: $mobile) {
    height: 54px;
    font-size: 1rem;
  }
}
.price-discount {
  color: #6b6b6b;
  text-decoration: line-through;
}
.order-card-counter {
  input {
    @media screen and (max-width: $mobile) {
      font-size: 1rem;
    }
  }
  button {
    @media screen and (max-width: $mobile) {
      width: 46px;
      height: 46px;
      font-size: 0.875rem;
    }
  }
}
.compact-counter {
  width: 160px;
  margin-left: var(--spacing-md);
}
</style>
