<template>
  <div :class="[$style.dropdown, ...dropdownClasses]">
    <div
      :class="[...callerClasses]"
      data-test="base-caller"
    >
      <slot name="base-caller" />
    </div>
    <div
      v-if="showValues"
      ref="valuesRef"
      :class="[$style.values, ...valuesClasses]"
      data-test="base-values"
    >
      <div>
        <slot name="base-values" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  showValues: boolean;
  dropdownClasses?: string[];
  callerClasses?: string[];
  valuesClasses?: string[];
};
const props = withDefaults(defineProps<Props>(), {
  showValues: false,
  dropdownClasses: () => [],
  callerClasses: () => [],
  valuesClasses: () => [],
});
const { showValues, dropdownClasses, callerClasses, valuesClasses } =
  toRefs(props);
const valuesRef = ref<HTMLElement | null>(null);
const emit = defineEmits(["outsideClick"]);
let timer: ReturnType<typeof setTimeout>;

watch(showValues, (isShown) => {
  if (isShown) {
    timer = setTimeout(setupListener, 0);
  } else {
    removeListener();
  }
});

function setupListener() {
  document.addEventListener("click", clickHandler);
}

function removeListener() {
  document.removeEventListener("click", clickHandler);
}

function clickHandler(e: MouseEvent) {
  if (!valuesRef.value) return;
  if (!valuesRef.value.contains(e.target as HTMLElement)) emit("outsideClick");
}

onBeforeUnmount(() => {
  clearTimeout(timer);
  removeListener();
});
</script>

<style lang="scss" module>
.dropdown {
  position: relative;
}
.values {
  position: absolute;
  z-index: 999;
  top: 100%;
  width: 100%;
}
</style>
