<template>
  <!-- Email -->
  <SharedInputError v-if="isUserMailError">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- General error -->
  <GeneralError v-if="isError">
    {{ t("errors.order_error") }}
  </GeneralError>
  <GeneralError v-if="errorText">
    {{ errorText }}
  </GeneralError>
  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="sendOrder"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import GeneralError from "./GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const inputValueMail = ref(userStore.userData?.email);

const isUserMailError = ref(false);
const isError = ref(false);
const errorText = ref<string | null>(null);

const sendOrder = async () => {
  if (productsStore.orderIsBlocked) return;

  if (!inputValueMail.value) {
    isUserMailError.value = true;
    return;
  }

  try {
    await productsStore.orderBulldropVoucher({
      email: inputValueMail.value!,
    });
  } catch (error: any) {
    if (error && error.data) {
      if (error.data.email) {
        errorText.value = error.data.email[0];
      }
    } else {
      isError.value = true;
    }
  }
};
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
</style>
