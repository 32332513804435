<template>
  <SimpleDropdown
    align="left"
    :show-values="showValues"
    :dropdown-classes="[$style.wrapper, $style[size]]"
    :caller-classes="[$style.select, disabled ? $style.disabled : '']"
    :values-classes="[$style.values, ...selectValuesClasses]"
    @outside-click="handleOutsideClick"
  >
    <template #caller>
      <div
        :class="$style.selected"
        @click="showValues = !showValues"
      >
        <div :class="$style.data">
          <template v-if="chosenValue">
            <div
              v-if="chosenValue.icon"
              :class="$style.img"
            >
              <img :src="chosenValue.icon" />
            </div>
            {{ chosenValue.name || chosenValue.value }}
          </template>
          <template v-else>{{ notSelectedText }}</template>
        </div>
        <IconChevronRight
          :class="[$style.icon, showValues && $style.iconOpened]"
        />
      </div>
    </template>
    <template #values>
      <div
        v-for="(item, index) in values"
        :key="item.value + index"
        :class="$style.value"
        @click="handleValueClick(item)"
      >
        <div
          v-if="item.icon"
          :class="$style.img"
        >
          <img :src="item.icon" />
        </div>
        {{ item.name || item.value }}
      </div>
    </template>
  </SimpleDropdown>
</template>

<script lang="ts" setup>
import SimpleDropdown from "~/components/Shared/Dropdown/SimpleDropdown.vue";

const showValues = ref(false);

type Option = {
  icon?: string;
  value: string;
  name?: string | number;
};

withDefaults(
  defineProps<{
    notSelectedText: string;
    values: Option[];
    size?: "l" | "m";
    disabled?: boolean;
    selectValuesClasses?: string[];
  }>(),
  {
    size: "l",
    disabled: false,
    selectValuesClasses: () => [],
  },
);

const emits = defineEmits(["change"]);

const chosenValue = ref<Option | null>(null);

const handleOutsideClick = () => {
  showValues.value = false;
};
const handleValueClick = (value: Option) => {
  chosenValue.value = value;
  showValues.value = false;
  emits("change", value.value);
};
</script>

<style module lang="scss">
.select {
  width: 100%;
  border-radius: var(--radius-max);
  background: var(--input-default);

  &:hover {
    background: var(--input-hover);
    color: var(--general-transparent-light-35);
  }

  &:active,
  &:focus {
    color: var(--general-transparent-light-100);

    .selected {
      color: var(--general-transparent-light-100);
    }
  }
}

.selected {
  color: var(--general-transparent-light-35);
  text-overflow: ellipsis;
  padding: var(--spacing-xs) var(--spacing-2xl);
  font-size: 14px;
  font-style: normal;
  line-height: 110%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.wrapper {
  display: inline-flex;
  height: 54px;
  cursor: pointer;
  width: 100%;
}

.l {
  height: 54px;
}

.m {
  height: 44px;
}

.icon {
  path {
    fill: var(--general-transparent-light-35);
  }
  width: 16px;
  height: 16px;
  transform: rotate(90deg);

  &Opened {
    transform: rotate(-90deg);
  }
}

.values {
  margin-top: -7px;
  background: var(--general-underlay-1-lvl);
  width: 100%;
  padding: var(--spacing-sm);
}

.value {
  padding: var(--spacing-md) var(--spacing-2xl) var(--spacing-md)
    var(--spacing-md);
  border-radius: var(--radius-2xl);
  height: 56px;
  color: var(--general-transparent-light-100);
  font-size: 16px;
  font-style: normal;
  line-height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--cells-hover);
  }
}

.img {
  width: 32px;
  height: 32px;
  border-radius: var(--radius-xs);
  overflow: hidden;
  margin-right: var(--spacing-md);

  img {
    width: 100%;
  }
}

.data {
  display: flex;
  align-items: center;
}

.disabled {
  background: var(--input-disabled);
  color: var(--general-transparent-light-35);
  pointer-events: none;
}
</style>
